function normalizeString(value: string) {
  return value
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^\w]/g, '')
    .toLowerCase();
}

export function stringsMatcher(str1: string, str2: string) {
  return normalizeString(str1) === normalizeString(str2);
}
