function getItem<T>(key: string): T | undefined {
  const existingData = localStorage.getItem(key);
  if (existingData) return JSON.parse(localStorage.getItem(key) || '{}');
  return undefined;
}

function setItem<T>(key: string, data: T): void {
  return localStorage.setItem(key, JSON.stringify(data));
}

function removeItem(key: string): void {
  return localStorage.removeItem(key);
}

export function getItemWithTTL(method: string, params: any) {
  const cacheKey = `${method}:${JSON.stringify(params)}`;
  const cacheExpirationKey = `${cacheKey}:expiration`;
  const cachedData = localStorage.getItem(cacheKey);
  const cacheExpiration = localStorage.getItem(cacheExpirationKey);
  const cacheIsNotExpired = cacheExpiration && Date.now() < Number(cacheExpiration)
  const cacheIsValid = cachedData && cacheIsNotExpired;

  if (cacheIsValid) {
    return JSON.parse(cachedData);
  }
}

export function setItemWithTTL(method: string, cacheTTL: number, responseData: any, params: any) {
  const cacheKey = `${method}:${JSON.stringify(params)}`;
  const cacheExpirationKey = `${cacheKey}:expiration`;
  localStorage.setItem(cacheKey, JSON.stringify(responseData));
  localStorage.setItem(cacheExpirationKey, (Date.now() + cacheTTL).toString());
}

export const localStorageMethods = {
  getItem,
  setItem,
  removeItem,
  getItemWithTTL,
  setItemWithTTL,
};
