import React from 'react';
import { Accordion, AccordionSummary, Typography, AccordionDetails, Link, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(() => ({
  link: {
    fontSize: "20px",
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: '#08312A',
    },
  },
}));

const FaqPage: React.FC<{ linkUrl: string }> = () => {
  const classes = useStyles();
  return (
    <div>
       <Accordion>
       <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.link}>
          1. O que é o Programa Abraçar a Vida?
        </Typography>
      </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Abraçar a Vida é um programa de suporte ao paciente com doenças crônicas acompanhando-o nos diferentes momentos da sua jornada (Diagnóstico, Acesso e Adesão). O programa contém, além de descontos, análises para dar suporte ao diagnóstico e apoio profissional para ajudar os pacientes e cuidadores a na adesão ao tratamento medicamentoso, promovendo um melhor entendimento sobre as doenças incluídos no programa (apenas indicações aprovadas em bula).
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.link}>2. Quais são as áreas terapêuticas participantes do Programa Abraçar a Vida?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Para verificar as indicações dos medicamentos participantes e saber mais sobre as áreas terapêuticas (doenças), clique na opção “Saúde” disponível na parte superior deste site.
            <br/>
            <br/>
            Caso tenha interesse em saber especificadamente sobre um determinado medicamento, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 8:00 às 20:00 horas, exceto feriados.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.link}>3. Quais são os serviços oferecidos no Programa Abraçar a Vida?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          A depender do medicamento participante, o Programa Abraçar a Vida oferece:
            <br/>
            <br/>
            • Desconto no momento da aquisição do medicamento para facilitar o acesso ao tratamento.
            <br/>
            <br/>
            • Suporte ao tratamento e rede de serviços em saúde com conteúdo e atendimentos exclusivos, dedicada especialmente aos pacientes nele inscritos e em tratamento com um ou mais medicamentos participantes.
            <br/>
            <br/>
            • Conteúdos exclusivos com dicas de saúde e tratamento.
            <br/>
            <br/>
            • Início de Tratamento para apoiar o(a) paciente no início do seu tratamento.
            <br/>
            <br/>
            • O profissional médico poderá acompanhar a jornada do(a) paciente no programa (área logada exclusiva para o profissional médico).
            <br/>
            <br/>
            • Análises para dar suporte ao diagnóstico e serviço de consultas com especialistas para dar uma segunda opinião no diagnóstico.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={classes.link}>4. Como faço para realizar o cadastro no Programa Abraçar a Vida?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           Para realizar o cadastro é necessário selecionar a opção “Cadastre-se” disponível na página inicial e preencher os campos solicitados. Importante que tenha em mãos os documentos pessoais do(a) paciente (CPF, telefone e endereço) e a <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>prescrição do profissional médico</span>.
            <br/>
            <br/>
            O cadastro também pode ser realizado em uma farmácia/ drogaria credenciada ou se preferir, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 8:00 às 20:00 horas, exceto feriados.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={classes.link}>5. A partir de quando o(a) paciente pode começar a usufruir dos benefícios do Programa Abraçar a Vida?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Após a conclusão do cadastro nos canais de atendimento, o(a) paciente já pode usufruir dos benefícios do programa. Não existe período de carência e a vigência é garantida enquanto o produto/ serviço estiver disponível.
            <br/>
            <br/>
            Para mais informações, leia o regulamento do programa clicando aqui. <Link href="https://patient.boehringer-ingelheim.com/br/abracar-a-vida/regulamento-e-autorizacoes" target="_blank" rel="noopener noreferrer">aqui</Link> 
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography className={classes.link}>6. No Programa Abraçar a Vida posso comprar quantas caixas do meu medicamento com desconto?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            As compras de medicamentos pelo Programa Abraçar a Vida estão limitadas a 4 caixas no período de 2 meses.
            <br/>
            <br/>
            Caso necessite de mais caixas ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 8:00 às 20:00 horas, exceto feriados.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography className={classes.link}>7. Como faço para comprar o medicamento com desconto?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            O(a) paciente cadastrado(a) poderá realizar a compra do medicamento com o número do CPF e a prescrição médica em uma das farmácias credenciadas (loja física) 
            que podem ser consultadas na área logada do(a) paciente (opção “buscador de loja”) ou nas <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>lojas online </span> 
            através do link <Link href="https://patient.boehringer-ingelheim.com/br/abracar-a-vida/onde-comprar" target="_blank" rel="noopener noreferrer">Links de compra e-commerce (boehringer-ingelheim.com)</Link>.
            <br/>
            <br/>
            Lembrando que, na área logada do(a) paciente também é possível efetuar a compra online através da opção “programe sua compra” (disponível ao lado direito na parte superior da página). Inclusive esta opção, além de permitir a compra online, também possibilita programar as próximas compras a depender da rede parceira.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8a-content"
          id="panel8a-header"
        >
          <Typography className={classes.link}>8. É possível comprar o medicamento pelo site com desconto?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Sim, é possível efetuar a compra online com desconto. As lojas online podem ser consultadas através do link: <Link href="https://patient.boehringer-ingelheim.com/br/abracar-a-vida/onde-comprar" target="_blank" rel="noopener noreferrer">Links de compra e-commerce (boehringer-ingelheim.com)</Link>.
            <br/>
            <br/>
            Caso a compra não seja finalizada, é necessário solicitar o cancelamento da transação contatando a farmácia, pois o limite de compras pode ser comprometido (consulte a pergunta 6 para verificar o limite de compras).
            <br/>
            <br/>
            Lembrando que, na área logada do(a) paciente também é possível efetuar a compra online através da opção “programe sua compra” (disponível ao lado direito na parte superior da página)“. Inclusive esta opção, além de permitir a compra online, também possibilita programar as próximas compras a depender da rede parceira.
            <br/>
            <br/>
            <span style={{ fontWeight: 'bold'}}>Importante</span>: a Boehringer Ingelheim não tem responsabilidade nas transações de compras realizadas nos sites das farmácias/ drogarias. Qualquer transtorno, por favor, entre em contato no serviço de atendimento do estabelecimento.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel9a-content"
          id="panel9a-header"
        >
          <Typography className={classes.link}>9. Como posso consultar o desconto e preço do(s) medicamento(s) que estou cadastrado(a)?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Para consultar os descontos e preços dos medicamentos acesse a página de “Login” (disponível na parte superior do lado direito do site) ou <Link href="https://abracaravida.funcionalmais.com/" target="_blank" rel="noopener noreferrer">clique aqui</Link>, selecione o tipo de público “paciente”, preencha o login e senha e em seguida selecione a opção “consulte seus descontos” (disponível no menu superior).
            <br/>
            <br/>
            Na página “consulte seus descontos” selecione o estado (campo disponível na parte superior do lado direito da tabela) para carregar as informações.
            <br/>
            <br/>
            Se preferir, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 8:00 às 20:00 horas, exceto feriados.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel10a-content"
          id="panel10a-header"
        >
          <Typography className={classes.link}>10. Quais são as redes credenciadas?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Para consultar as farmácias credenciadas acesse a página de “Login” (disponível na parte superior do lado direito do site) ou <Link href="https://abracaravida.funcionalmais.com/" target="_blank" rel="noopener noreferrer">clique aqui</Link>, selecione o tipo de público “paciente”, preencha o login e senha e em seguida selecione a opção “encontre uma loja”. Após inserir o número do CEP, serão apresentadas as farmácias credenciadas mais próxima da região consultada.
            <br/>
            <br/>
            Se preferir, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 8:00 às 20:00 horas, exceto feriados.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel11a-content"
          id="panel11a-header"
        >
          <Typography className={classes.link}>11. Como faço para agendar o atendimento com o(s) especialista(s)?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Para agendar o atendimento com o especialista, acesse a página de “Login” (disponível na parte superior do lado direito do site) ou <Link href="https://abracaravida.funcionalmais.com/" target="_blank" rel="noopener noreferrer">clique aqui</Link>, selecione o tipo de público “paciente”, preencha o login e senha e em seguida selecione a opção “agendamentos”. Por fim, selecione o especialista que deseja e o dia e horário de sua preferência.
            <br/>
            <br/>
            Após o agendamento, acesse a sala de consulta pelo link recebido no seu e-mail 5 minutos antes do horário agendado. Verifique se a sua câmera e microfone estão configurados.
            <br/>
            <br/>
            Se preferir, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 9:00 às 18:00 horas (exceto feriados) para agendamento.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel12a-content"
          id="panel12a-header"
        >
          <Typography className={classes.link}>12. Estou com dificuldade em montar/manusear o meu dispositivo Respimat. Como posso obter orientações?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Dispomos de um atendimento especializado para orientações de montagem e manuseio do dispositivo Respimat.
            <br/>
            <br/>
            Para agendar o atendimento com o especialista, acesse a página de “Login” (disponível na parte superior do lado direito do site) ou <Link href="https://abracaravida.funcionalmais.com/" target="_blank" rel="noopener noreferrer">clique aqui</Link>, selecione o tipo de público “paciente”, preencha o login e senha e em seguida selecione a opção “agendamentos”. Por fim, selecione o especialista que deseja e o dia e horário de sua preferência.
            <br/>
            <br/>
            Após o agendamento, acesse a sala de consulta pelo link recebido no seu e-mail 5 minutos antes do horário agendado. Verifique se a sua câmera e microfone estão configurados.
            <br/>
            <br/>
            Se preferir, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 9:00 às 18:00 horas (exceto feriados) para agendamento e/ou para esclarecer as suas dúvidas sobre o dispositivo.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel13a-content"
          id="panel13a-header"
        >
          <Typography className={classes.link}>13. Como faço para atualizar os meus dados pessoais e incluir novo(s) medicamento(s) no meu cadastro?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Para atualizar o seu cadastro, acesse a página de “Login” (disponível na parte superior do lado direito do site) ou <Link href="https://abracaravida.funcionalmais.com/" target="_blank" rel="noopener noreferrer">clique aqui</Link>, selecione o tipo de público “paciente” e preencha o login e senha. Após acessar a área logada, clique em cima do seu nome disponível no canto superior direito e selecione a opção “editar minhas informações”.
            <br/>
            <br/>
            Nesta opção é possível alterar os dados pessoais e incluir novos medicamentos que foram prescritos pelo profissional médico. Após realizar os ajustes necessários, clique na opção “editar meu cadastro” para salvar as alterações.
            <br/>
            <br/>
            Se preferir, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 8:00 às 20:00 horas, exceto feriados.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel14a-content"
          id="panel14a-header"
        >
          <Typography className={classes.link}>14. Não consegui comprar o medicamento com desconto na farmácia credenciada. O que devo fazer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Por favor, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 8:00 às 20:00 horas (exceto feriados), a fim de analisarmos a situação do cadastro. Informe o nome do produto prescrito pelo profissional médico, dados da farmácia (nome, endereço e telefone, se possível) e o motivo informado pela farmácia para não concluir a venda. Se necessário, o caso será direcionado à área responsável para resolução.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel15a-content"
          id="panel15a-header"
        >
          <Typography className={classes.link}>15. Esqueci a minha senha para acessar a área logada. Como faço para redefini-la?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Acesse a página de “Login” (disponível na parte superior do lado direito do site), selecione o tipo de público (paciente ou médico) e em seguida selecione a opção “Esqueceu sua senha?”. Preencha o campo com a informação solicitada e selecione a opção “Quero redefinir a minha senha”. Se preferir, clique aqui para já ser direcionado(a) para a página de redefinição de senha.
          <br/>
          Caso tenha algum problema na redefinição de senha, ligue gratuitamente para 0800 701 6633 e selecione opção 2 de segunda a sexta-feira das 8:00 às 20:00 horas, exceto feriados.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel16a-content"
          id="panel16a-header"
        >
          <Typography className={classes.link}>16. Utilizei um medicamento da Boehringer Ingelheim e apresentei um efeito indesejado. O que devo fazer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Por favor, entre em contato com o nosso SAC através do 0800 701 6633 e selecione opção 3 ou pelo e-mail: <Link href="mailto:sac.brasil@boehringer-ingelheim.com">sac.brasil@boehringer-ingelheim.com</Link>. O horário de atendimento é de segunda a sexta-feira das 8:00 às 18:00 horas, exceto feriados.
            <br/>
            <br/>
            Após o horário de expediente, entre em contato com o Centro de Assistência Toxicológica (CEATOX) do Hospital das Clínicas no telefone 0800 014 8110, que está disponível 24 horas, 7 dias de semana.
            <br/>
            <br/>
            Salientamos que todas as informações coletadas serão armazenadas e processadas para monitoramento da segurança dos medicamentos e, quando necessário pelas regras regulatórias, as informações serão compartilhadas com as autoridades sanitárias local ou global.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel17a-content"
          id="panel17a-header"
        >
          <Typography className={classes.link}>17. Comprei um medicamento que está alterado ou com defeito. O que devo fazer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Por favor, entre em contato com o nosso SAC através 0800 701 6633 e selecione opção 3 ou pelo e-mail: <Link href="mailto:sac.brasil@boehringer-ingelheim.com">sac.brasil@boehringer-ingelheim.com</Link>.
            <br/>
            <br/>
            O horário de atendimento é de segunda a sexta-feira das 8:00 às 18:00 horas, exceto feriados.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel18a-content"
          id="panel18a-header"
        >
          <Typography className={classes.link}>18. Como faço o credenciamento do meu estabelecimento (farmácia ou drogaria) no Programa Abraçar a Vida?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Para credenciar o seu estabelecimento no Programa Abraçar a Vida acesse o site: <Link href="https://patient.boehringer-ingelheim.com/br/abracar-a-vida/external-link?external=https%3A%2F%2Fwww.portaldadrogaria.com.br%2F11v1" target="_blank" rel="noopener noreferrer">Bem-vindo - Portal da Drogaria</Link>.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel19a-content"
          id="panel19a-header"
        >
          <Typography className={classes.link}>19. Sou balconista/farmacêutico(a) e gostaria de saber como cadastrar ou cancelar compras dos pacientes. O que devo fazer?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Por favor, entre em contato com o “Suporte a Farmácias” através do telefone (11) 3321-9433 de segunda a sexta-feira das 09:00 às 18:00 horas (exceto feriados) ou se preferir, acesse a aba “Ajuda” disponível no Portal da Drogaria.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FaqPage;
