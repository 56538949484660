import { authenticatedGetRequest, authenticatedPostRequest } from './authenticatedRequestService';
import { IFindAllAccreditedLocations } from '../pages/portal-patient/accredited/contracts/accreditadeLocationInterface';
import { IAccreditedLocationParams } from '../pages/portal-patient/accredited/contracts/accreditedFormDataInterface';
import { localStorageMethods } from '../pages/portal-patient/common/helpers';

async function getListCities(
  programCode: number,
  state: string,
): Promise<{ listCities: { code: number; name: string }[] }> {
  const method = 'address/GetListCities';
  const body = { programCode, state };

  try {
    const response = await authenticatedPostRequest(method, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function getAddressByCEP(cep: string): Promise<any> {
  const method = 'integration/get-address-by-cep';
  const params = `/${cep}`;
  const cepLengthWithPontuation = 9;

  if (cep?.length !== cepLengthWithPontuation) {
    throw new Error('CEP inválido');
  }

  try {
    const response = await authenticatedGetRequest(method, params)
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function listNeighborhoods(
  programCode: number,
  cityCode: number,
  state: string,
): Promise<{ listNeighborhoods: { code: number; description: string }[] }> {
  const method = 'address/ListNeighborhoods';
  const body = { programCode, cityCode, state };

  try {
    const response = await authenticatedPostRequest(method, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function ListAccreditedLocation(body: IAccreditedLocationParams): Promise<{ listAccreditedLocation: IFindAllAccreditedLocations }> {
  const method = 'accredited/ListAccreditedLocation';

  try {
    const response = await authenticatedPostRequest(method, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
}

async function listAccreditedLocationByCep(body: IAccreditedLocationParams): Promise<{ listAccreditedLocation: IFindAllAccreditedLocations }> {
  const method = 'accredited/ListAccreditedLocationByCep';
  const cacheTTL = 60 * 60 * 1000; // Cache time-to-live: 1 hour
  const cachedData = localStorageMethods.getItemWithTTL(method, body);
  if (cachedData) return cachedData;

  try {
    const response = await authenticatedPostRequest(method, body);
    const responseData = response?.data;
    localStorageMethods.setItemWithTTL(method, cacheTTL, responseData, body);

    return responseData;
  } catch (error) {
    throw error;
  }
}

export const addressAndAccreditedService = {
  getListCities,
  getAddressByCEP,
  listNeighborhoods,
  ListAccreditedLocation,
  listAccreditedLocationByCep,
};
