import React from 'react';
import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';

interface FilterProps {
  filter: string;
  setFilter: (filter: string) => void;
}

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto 20px;
  padding: 10px;
  width: 100%;
  max-width: 800px;
  border: 1px solid #ccc;
  border-radius: 7px;
  background-color: #fff;
  max-width: 100%;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.41px;
  text-align: left;

  @media (max-width: 768px) {
    padding: 8px;
  }
`;

const FilterIcon = styled(SearchIcon)`
  margin-right: 10px;
  color: #ccc;
`;

const FilterInput = styled.input`
  border: none;
  flex: 1;
  font-size: 16px;
  &:focus {
    outline: none;
  }
`;

const Title = styled.h4`
  font-family: Roboto;
  font-weight: 400;
  text-align: left;
  margin-bottom: 20px;
  font-size: 18px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-family: Roboto;
    font-weight: 400;
    text-align: left;
    font-size: 16px;
  }
`;

export function Filter({ filter, setFilter }: FilterProps) {
  return (
    <>
      <Title>
        Encontre aqui a bula do seu medicamento e todas as informações necessárias para o uso
        consciente e informado, contribuindo para o seu bem-estar e saúde.
      </Title>
      <FilterContainer>
        <FilterIcon />
        <FilterInput
          type="text"
          placeholder="Busque e explore os medicamentos"
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
        />
      </FilterContainer>
    </>
  );
}
