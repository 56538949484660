import styled from 'styled-components';

export const Button = styled.div`
    width: 182px;
    margin-left: 0;

    button {
        background-color: var(--primary-main);
        width: 100%;
        height: 44px;
        border-radius: 999px;
        border: none;
        color: #08312A;
        font-size: 16px;
        text-align: center;
        font-weight: 600;
        font-family: 'Roboto', sans-serif;
        cursor: pointer;
        transition: background-color 0.3s;

        &:disabled {
            background-color: #a9a9a9;
            cursor: not-allowed;
        }

        &:not(:disabled) {
            background-color: #00E47C;
        }
    }
`;
