import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { IFindAllMedicines, IFindMedicinesByPatient, IFindNameDocutorAndSpecialty, LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';
import envConfig from '../config/environment-config';
import { authenticatedPostRequest } from './authenticatedRequestService';

function getOriginCode(pathname?: string): number {
  return pathname && pathname.includes('/unimed') ? envConfig.originCodeUnimed : envConfig.originCode;
}

function findManyByProgramCode(
  programCode: number,
  pathname?: string,
): UseQueryResult<AxiosResponse<IFindAllMedicines, any>, unknown> {
  const method = 'medicine/ConsultMedicinePermissionRegistrationOrigin';
  const body = {
    programCode,
    originCode: getOriginCode(pathname),
  };
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.FIND_ALL_MEDICINES_DATA, programCode],
    () => authenticatedPostRequest(
      method,
      body,
    ),
    {
      staleTime,
      cacheTime,
    },
  );
}

function findManyByPatient(
  programCode: number,
  healthPassportId: string,
): UseQueryResult<AxiosResponse<IFindMedicinesByPatient[], any>, unknown> {
  const method = 'medicine/ConsultMedicalInformation';
  const body = {
    programCode,
    healthPassportId,
  };
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 10000; //ms

  return useQuery(
    [LocalStorageKeysEnum.FIND_MEDICINES_BY_PATIENT, healthPassportId],
    () => authenticatedPostRequest(
      method,
      body,
    ),
    {
      staleTime,
      cacheTime,
    },
  );
}

function addByPatient(body: any, pathname?: string) {
  const method = 'medicine/RegisterMedicine';
  const requestBody = {
    ...body,
    originCode: getOriginCode(pathname),
  };

  return authenticatedPostRequest(
    method,
    requestBody,
  );
}

function findDoctorNameAndSpecialty(
  programCode: number,
  CRM: string,
  uf: string,
  isEnable: boolean
): UseQueryResult<AxiosResponse<IFindNameDocutorAndSpecialty, any>, unknown> {
  const method = 'medicine/ConsultDoctorInformation';
  const body = { programCode, CRM, uf };
  const staleTime = 60 * 10000; // ms
  const cacheTime = 60 * 10000; // ms
  const infoValid: boolean = !!CRM && !!uf && CRM.trim() !== '' && uf.trim() !== '';

  return useQuery(
    [LocalStorageKeysEnum.FIND_DOCTOR_NAME_AND_SPECIALTY, {CRM, uf}],
    () => authenticatedPostRequest(method, body),
    {
      staleTime,
      cacheTime,
      enabled: isEnable && infoValid,
      retry: false,
    }
  );
}

export const medicinesService = {
  findManyByProgramCode,
  findManyByPatient,
  addByPatient,
  findDoctorNameAndSpecialty,
};
