import styled from 'styled-components';

export const InputTitle = styled.p`
  font-size: 16px;
  color: black;
  line-height: 18.75px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
`;

export const InputSubTitle = styled.p`
  font-size: 14px;
  color: #282929;
  line-height: 16.41px;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
  margin-top: -14px;
`;

export const InputsBox = styled.div<{width?: string}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: ${(prop: {width?: string}) => prop.width ?? '100%'};
`;

export const Box = styled.div`
  margin-bottom: 40px;
`;

export const inputMUIDefaultStyles = {
  small: { width: '79px', height: '44px' },
  medium: { width: '181px', height: '44px' },
  normal: { width: '283px', height: '44px' },
  large: { width: '100%', height: '44px' },
};

export const AddMoreText = styled.p`
  color: #EE6541;
  font-size: 14px;
  line-height: 16.41px;
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  cursor: pointer;
`;

export const BoxFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const inputLabelProps = {
  style: { marginTop: '4px' },
};

export const Container = styled.div`
  width: 600px;
  margin: 30px auto;
`;
