import { IAlertMessage } from '../../common/contracts';

export const scheduleFormAlertMessages: IAlertMessage = {
  success: {
    title: 'Agendamento feito com sucesso.',
    subTitle: 'Você será redirecionado para visualizar seus agendamentos.',
    status: 'success',
  },
  error: {
    title: 'Não foi possível adicionar agendamento.',
    subTitle: '',
    status: 'error',
  },
};
