import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom'; 
import { noop, set } from 'lodash';
import { Button, inputMUIDefaultStyles } from '../../common/styles';
import { FormControl, Grid, Box, CircularProgress, Container, Typography, TextField, GridSize } from '@material-ui/core';
import { localStorageMethods } from '../../common/helpers';
import { IPatientCrendentials, IUseFormData, LocalStorageKeysEnum } from '../../common/contracts';
import { RoutesEnum } from '../../../../constants';
import { authenticatedGetRequest, medicinesService, patientDataService, schedulesService } from '../../../../services';
import { useAlert } from '../../../../contexts/patient-portal/Alert.context';
import envConfig from '../../../../config/environment-config';
import { useQueryClient } from 'react-query';
import { useLoader } from '../../../../contexts/patient-portal/Loader.context';
import { SelectInput } from '../../common/components';
import { IGetScheduleById, IListAvailabilities, IListProfessionals } from '../contracts';
import { MuiPickersUtilsProvider, KeyboardDatePicker  } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { prepareAddScheduleData, scheduleFormAlertMessages } from '../helpers';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';

export type MessagesState = {
  availableDayMessage: string;
  availableHourMessage: string;
};

const defaultMessagesState: MessagesState = {
  availableDayMessage: 'Selecionar o Dia',
  availableHourMessage: 'Selecionar o Horário',
};

export type EditFieldState = {
  sizeXs: GridSize;
  sizeSm: GridSize;
  disabled: boolean;
  registerId: string;
  value: string | undefined;
};

export function ScheduleForm(): JSX.Element {
  interface RouteParams {
    id: string;
  }

  const { success, error } = scheduleFormAlertMessages;
  const queryClient = useQueryClient();
  const { showLoader, hideLoader } = useLoader();
  const { push } = useHistory();
  const { id } = useParams<RouteParams>();
  const location = useLocation(); 
  const [callApi, setCallApi] = useState<boolean>(false);
  const [isLoadingDefaultValue, setIsLoadingDefaultValue] = useState<boolean>(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editModeFields, setEditModeFields] = useState<EditFieldState[]>([]);
  const [professionals, setProfessionals] = useState<Array<{ label: string; value: string; }> | never[]>([]);
  const [availabilities, setAvailabilities] = useState<Array<any> | never[]>([]);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [availableHours, setAvailableHours] = useState<Array<{ label: string; value: string; }> | never[]>([]);
  const [selectedAvailability, setSelectedAvailability] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [messageState, setMessageState] = useState<MessagesState>(defaultMessagesState);
  const { showAlert, hideAlert } = useAlert();
  const patientCredentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    getValues,
    watch,
  } = useForm();

  const formData: IUseFormData = {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    watch,
    getValues,
    unregister: noop,
  };
  const { showDialogue } = useDialogue();

  const pathname = location.pathname; 
  const origin = pathname.includes('/unimed') ? '/unimed' : '';
  const { data: professionalTypesData, isLoading: isLoadingProfessionalTypes } = schedulesService.listProfessionalTypes();
  const { data: crmProductsData, isLoading: isLoadingProducts } = schedulesService.listCrmProducts(envConfig.boehringerCrmIdIndustry);

  const { data: patientData, isLoading: isLoadingPatientData } = patientDataService.getPatientData(
    Number(envConfig.boehringerProgramCode),
    patientCredentials?.healthPassportId || '',
    setCallApi,
  );

  const unavailableProfessionalTypes: {[key: string]: string} = {
    "4": "Farmacêutico",
    "3": "Enfermeiro"
  };
  
  const professionalTypes = professionalTypesData?.data?.types?.map(type => ({
    label: type.value,
    value: type.key.toString(),
  })) || [];

  const { data: medicinesByPatient } = medicinesService.findManyByPatient(
    envConfig.boehringerProgramCode,
    patientCredentials?.healthPassportId || ''
  );

  const medicineEANSByPatient = medicinesByPatient?.data?.map(medicine => medicine.EAN) || [];

  const filteredProductsData = crmProductsData?.data?.products?.filter(product => {
    const productEAN = product.ean;
    const match = medicineEANSByPatient.includes(productEAN);
    return match;
  }) || [];

  const products = filteredProductsData?.map(product => ({
    label: `${product.popularName} - ${product.ean}`,
    value: product.idProduct.toString(),
  })) || [];

  useEffect(() => {
    if (id) {
      try {
        showLoader();
        const fetchSchedule = async () => {
          setIsEditMode(true);
          const response = (await authenticatedGetRequest('schedule/get-by-id', `/${id}`)).data as IGetScheduleById;
  
          if (response.schedule) {
            const fieldProps: EditFieldState[] = [];

            fieldProps.push({ sizeXs: 12, sizeSm: 12, disabled: true, registerId: 'idProduct', value: response.schedule?.product?.ean ? `${response.schedule?.product?.popularName} - ${response.schedule?.product?.ean}` : 'Não Informado'})
            fieldProps.push({ sizeXs: 12, sizeSm: 6, disabled: true, registerId: 'idType', value: response.schedule?.professional?.professionalTypeDescription })
            fieldProps.push({ sizeXs: 12, sizeSm: 6, disabled: true, registerId: 'idProfessional', value: response.schedule?.professional?.name })
            fieldProps.push({ sizeXs: 12, sizeSm: 6, disabled: true, registerId: 'idAvailability', value: response.schedule?.availability?.availableDate })
            fieldProps.push({ sizeXs: 12, sizeSm: 6, disabled: true, registerId: 'idAvailabilityTime', value: response.schedule?.availabilityTime?.availableTime })
            fieldProps.push({ sizeXs: 12, sizeSm: 12, disabled: true, registerId: 'observations', value: response.schedule.observations })

            setEditModeFields(fieldProps);
          }
        }
  
        fetchSchedule();
        hideLoader();
      } catch (e: any) {
        hideLoader();
        const errorMessage = e?.response?.data?.message || ''
        showAlert('Falha ao buscar dados do agendamento', errorMessage, 'error');
        setTimeout(() => hideAlert(), 3000);
        setTimeout(() => push(`${RoutesEnum.PATIENT_SCHEDULES_ROUTE}${origin}`), 2600);
      } finally {
        hideLoader();
      }
    }
  }, [id, setValue]);

  const checkUnavailableProfessionalTypes = (value: string): boolean => {
    const valueIsUnvailable = unavailableProfessionalTypes[value.toString()];
    if (!valueIsUnvailable) return true; 
    
    setProfessionals([]);
    setAvailabilities([]);
    setAvailableDates([]);
    setAvailableHours(null as any);
    setSelectedDate(null);
    return Boolean(
      showDialogue(
        'Agendamento disponível pela central', 
        `Para agendar consulta com ${valueIsUnvailable.toLowerCase()}, por favor, entre em contato com o 0800 701 6633. Estamos te esperando.`, 
        'info',
      )
    );
  }

  const handleProfessionalTypeChange = async (e: React.ChangeEvent<{ value: unknown }>) => {
    if(e) {
      try {
        const typeIsAvailable = checkUnavailableProfessionalTypes(String(e.target.value));
        if(!typeIsAvailable) return;
        
        showLoader();
        setProfessionals([]);
        setAvailabilities([]);
        setAvailableDates([]);
        setMessageState(defaultMessagesState);

        const selectedValue = String(e.target.value);
        const method = 'schedule/professionals';
        const params = `/${envConfig.boehringerProgramFollowup}/${selectedValue}`;
        const response = (await authenticatedGetRequest(method, params)).data as IListProfessionals;
  
        if(response?.professionals?.length > 0) {
          const professionalsList = response?.professionals?.map(professional => ({
            label: professional.name,
            value: professional.idProfessional.toString(),
          })) || [];
          setProfessionals(professionalsList);
        }

        hideLoader();
      } catch (e: any) {
        hideLoader();
        const errorMessage = e?.response?.data?.message || ''
        showAlert('Falha ao buscar tipos de agendamento', errorMessage, 'error');
        setTimeout(() => hideAlert(), 3000);
      }
    }
  };

  const handleProfessionalsChange = async (e: React.ChangeEvent<{ value: unknown }>) => {
    if(e) {
      try {
        showLoader();
        setAvailabilities([]);
        setAvailableDates([]);
        setMessageState(defaultMessagesState);
        
        const selectedValue = String(e.target.value);
        const method = 'schedule/availabilities-by-professional';
        const params = `/${envConfig.boehringerProgramFollowup}/${selectedValue}`;
        const response = (await authenticatedGetRequest(method, params)).data as IListAvailabilities;

        if(response?.availabilities?.length > 0) {
          setAvailabilities(response.availabilities);
          setAvailableDates(response.availabilities.map(x => x.availableDate))
        } else {
          setMessageState({ availableDayMessage: 'Nenhum Dia Disponível',availableHourMessage: 'Nenhum Horário Disponível' });
        }
        hideLoader();
      } catch (e: any) {
        hideLoader();
        const errorMessage = e?.response?.data?.message || ''
        showAlert('Falha ao buscar profissionais', errorMessage, 'error');
        setTimeout(() => hideAlert(), 3000);
      }
    }
  };

  function convertToDDMMYYYY(isoDateString: string) {
    const parts = isoDateString.split('-');
    const [year, month, day] = parts;
    return `${day}/${month}/${year}`;
  }

  const isDateDisabled = (date: any) => {
    const formattedDate = convertToDDMMYYYY(date.toISOString().slice(0, 10));
    return !availableDates.includes(formattedDate);
  };

  useEffect(() => {
    setIsLoadingDefaultValue(isLoadingPatientData);
    setIsLoadingDefaultValue(isLoadingProducts);
    setIsLoadingDefaultValue(isLoadingProfessionalTypes);
  }, [isLoadingPatientData, isLoadingProducts, isLoadingProfessionalTypes, availabilities, availableDates, defaultMessagesState]);


  const handleDateChange = (date: any) => {
    try {
      if(!date || date != 'Invalid Date') {
        const formattedDate = convertToDDMMYYYY(date.toISOString().slice(0, 10));
        const availability = availabilities.find(availability => availability.availableDate == formattedDate);
        const availableHourList = availability?.availabilityTime.map((time: { availableTime: any; idAvailabilityTime: any }) => ({
          label: time.availableTime,
          value: time.idAvailabilityTime.toString(),
        })) || [];

        if(availableHourList.length > 0) {
          setAvailableHours(availableHourList);
        } else { 
          setMessageState({ ...messageState, availableHourMessage: 'Nenhum Horário Disponível' });
        }
  
        setSelectedAvailability(availability.idAvailability);
        setSelectedDate(date);
      }
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message || ''
      showAlert('Falha ao buscar disponibilidades', errorMessage, 'error');
      setTimeout(() => hideAlert(), 3000);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      showLoader();

      data.idSchedule = id ? id : '';
      data.idAvailability = selectedAvailability;
      data.patientDocument = patientData?.data.cpf;

      const preparedData = prepareAddScheduleData(data);
      await schedulesService.create(preparedData);
      await queryClient.invalidateQueries([LocalStorageKeysEnum.FIND_SCHEDULES_BY_PATIENT_FOLLOWUP, `/acompanhemento_unico/${patientData?.data.cpf}`]);
      showAlert(success.title, success.subTitle, success.status);
      setTimeout(() => hideAlert(), 3000);
      setTimeout(() => push(`${RoutesEnum.PATIENT_SCHEDULES_ROUTE}${origin}`), 800);
      hideLoader();
    } catch (e: any) {
      hideLoader();
      const errorMessage = e?.response?.data?.message || ''
      showAlert(error.title, errorMessage, error.status);
      setTimeout(() => hideAlert(), 3000);
    }
  };

  if (isLoadingDefaultValue) {
    return (
      <div style={{ margin: '200px auto', marginLeft: '48%' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body2" component="span" style={{ color: '#000000', cursor: 'pointer' }} onClick={() => push(`${RoutesEnum.PATIENT_SCHEDULES_ROUTE}${origin}`)}> 
          <b>Meus agendamentos</b>
        </Typography>
        <Typography variant="body2" component="span" style={{ color: '#808080', margin: '0 5px' }}>
          /
        </Typography>
        <Typography variant="body2" component="span" style={{ color: '#808080' }}>
          {isEditMode ? 'Editar agendamento' : 'Adicionar agendamento'}
        </Typography>

        <hr style={{ border: '1px solid lightgray', margin: '24px 0', width: '100%' }} />
        
        <Box mb={5} mt={2}>
          {
            !isEditMode ?
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12}>
                <SelectInput
                  title="Selecione o Produto"
                  isRequired
                  list={products}
                  style={inputMUIDefaultStyles.large}
                  formData={formData}
                  propertieFormName={'idProduct'}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  title="Selecione o tipo de agendamento"
                  isRequired
                  list={professionalTypes}
                  style={inputMUIDefaultStyles.large}
                  formData={formData}
                  propertieFormName={'idType'}
                  onChange={handleProfessionalTypeChange}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  title="Selecione o Profissional"
                  isRequired
                  list={professionals}
                  style={inputMUIDefaultStyles.large}
                  formData={formData}
                  propertieFormName={'idProfessional'}
                  disabled={professionals?.length > 0 ? false : true}
                  onChange={handleProfessionalsChange}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} style={{ marginTop: 20 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    required
                    title={messageState.availableDayMessage}
                    helperText={messageState.availableDayMessage}
                    style={inputMUIDefaultStyles.large}
                    disabled={availabilities?.length <= 0}
                    disablePast
                    value={selectedDate}
                    onChange={handleDateChange}
                    shouldDisableDate={isDateDisabled}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  isRequired
                  title={messageState.availableHourMessage}
                  list={availableHours}
                  style={inputMUIDefaultStyles.large}
                  formData={formData}
                  propertieFormName={'idAvailabilityTime'}
                  disabled={selectedDate && availableHours.length > 0 ? false : true}
                />
                <FormControl
                  variant="outlined"
                  fullWidth
                >
                </FormControl>
              </Grid>
            </Grid>
            :
            <Grid container spacing={4}>
              {
                editModeFields.map((value, index) => (
                  <Grid item xs={value.sizeXs} sm={value.sizeSm} key={index}>
                    <TextField
                      key={index}
                      disabled
                      variant="outlined"
                      style={inputMUIDefaultStyles.large}
                      value={value.value}
                      {...register(value.registerId)}
                    />
                  </Grid>
                ))
              }
            </Grid>
          }
        </Box>

        <Box>
          {
            !isEditMode ?
            <Button type='default'>
              <button type="submit">{isEditMode ? 'Atualizar' : 'Adicionar'}</button>
            </Button>
            :
            <div> </div>
          }
        </Box>

      </form>
    </Container>
  );
}
