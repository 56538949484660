import React, { useEffect, useRef } from 'react';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { IFindAllAccreditedLocations } from '../contracts/accreditadeLocationInterface';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import envConfig from '../../../../config/environment-config';

interface AccreditedMapProps {
  center: { lat: number; lng: number };
  results: IFindAllAccreditedLocations['listAccredited'];
  onLoad: () => void;
  onError: () => void;
}

const containerStyle = {
  width: '100%',
  height: '500px',
};

function formatCEP(cep: string) {
  cep = cep.replace(/\D/g, '');

  if (cep.length === 8) {
    return `${cep.slice(0, 5)}-${cep.slice(5)}`;
  }
  return cep;
}

export function AccreditedMap({ center, results, onLoad, onError }: AccreditedMapProps): JSX.Element {
  const mapRef = useRef<google.maps.Map | null>(null);
  const markersRef = useRef<google.maps.Marker[]>([]);
  const infoWindowRef = useRef<google.maps.InfoWindow | null>(null);
  const markerClustererRef = useRef<MarkerClusterer | null>(null);

  const addMarkers = () => {
    markersRef.current.forEach(marker => marker.setMap(null));
    markersRef.current = [];

    if (infoWindowRef.current) {
      infoWindowRef.current.close();
    }

    if (markerClustererRef.current) {
      markerClustererRef.current.clearMarkers();
    }

    const markers = results.map(location => {
      const lat = Number(location.latitude);
      const lng = Number(location.longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        const marker = new google.maps.Marker({
          position: { lat, lng },
          title: location.tradeName,
          map: mapRef.current,
        });

        marker.addListener('click', () => {
          if (infoWindowRef.current) {
            infoWindowRef.current.close();
          }

          const formattedZipCode = formatCEP(location.zipCode);

          const infoWindow = new google.maps.InfoWindow({
            content: `<div><strong>${location.tradeName}</strong><br>${location.address}, ${location.city}, ${location.state} - ${formattedZipCode}</div>`,
          });

          infoWindow.open(marker.getMap(), marker);
          infoWindowRef.current = infoWindow;
        });

        return marker;
      } else {
        return null;
      }
    }).filter(marker => marker !== null);

    markersRef.current = markers as google.maps.Marker[];

    if (markersRef.current.length > 0) {
      markerClustererRef.current = new MarkerClusterer({
        map: mapRef.current!,
        markers: markersRef.current,
      });
    }
  };

  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map;
    onLoad();

    if (results.length > 0) {
      addMarkers();
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      addMarkers();
    }
  }, [results]);

  return (
    <LoadScript
      googleMapsApiKey={envConfig.googleApiKey}
      onLoad={onLoad}
      onError={onError}
    >
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={12}
        onLoad={handleMapLoad}
        onUnmount={() => {
          if (mapRef.current) {
            mapRef.current = null;
          }
        }}
      />
    </LoadScript>
  );
}
