import React from 'react';
import ExternalPage from '../../../components/Faq';

const FaqPage: React.FC<{ url?: string }> = ({ url }): JSX.Element => {
  const defaultUrl = 'https://patient.boehringer-ingelheim.com/br/abracar-a-vida/perguntas-frequentes-abracar-vida';

  return <ExternalPage linkUrl={url || defaultUrl} />;  
};

export default FaqPage;
