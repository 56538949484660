import React from 'react';
import styled from 'styled-components';
import { IUseFormData } from '../contracts';

interface HelperTextProps {
  display?: string; // Optional prop to control the display value
}

interface SelectProps {
  disabled?: boolean; // Optional prop to control the display value
}

const FormControl = styled.div`
  width: 100%;
  margin: 10px 0;
  position: relative;
`;

const Select = styled.select<SelectProps>`
  width: 100%;
  height: 62px;
  padding: 12px;
  border: 1px solid #ccc;
  margin-top: -5px;
  border-radius: 6px;
  appearance: none;
  background: #fff url('data:image/svg+xml;utf8,<svg fill="%23000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
  background-size: 16px;
  font-size: 16px;
  color: #8b8b8b;
  cursor: pointer;
  white-space: normal; 
  word-wrap: break-word; 
  background-color: ${({ disabled }: { disabled?: boolean }) => disabled ? '#dfdfdf' : '#fff'};

  &:invalid {
    color: #aaa;
  }

  &:focus {
    border: 2px solid #08312A;
    outline: none; 
  }

  @media (max-width: 600px) {
    font-size: 14px;
    height: 68px;
    white-space: normal; 
    word-wrap: break-word; 
  }
`;

const MenuItem = styled.option`
  white-space: normal; 
  word-wrap: break-word; 
`;

const HelperText = styled.p<HelperTextProps>`
  margin-left: 15px;
  color: grey;
  margin-top: 4px;
  display: ${({ display = 'none' }) => display};
  font-size: 12px;
`;

export function SelectInput({
  title,
  list,
  style,
  formData,
  propertieFormName,
  onChange,
  isRequired,
  defaultValue,
  disabled,
  className,
  helperText
}: {
  title: string,
  list: { value: string, label: string }[],
  style: any,
  formData: IUseFormData,
  propertieFormName: string,
  onChange?: any,
  isRequired?: boolean,
  defaultValue?: any,
  disabled?: boolean,
  className?: string,
  helperText?: string,
}) {
  const { register } = formData;

  return (
    <FormControl style={style}>
      <Select
        required={isRequired}
        {...register(propertieFormName, { required: isRequired ?? false })}
        onChange={onChange}
        defaultValue={defaultValue}
        disabled={disabled}
      >
        <MenuItem value="" hidden>
          {title} {isRequired ? '*' : ''}
        </MenuItem>
        {list?.map((v, i) => (
          <MenuItem value={v.value} key={i}>
            {v.label}
          </MenuItem>
        ))}
      </Select>
      <HelperText display={helperText ? 'initial' : 'none'}>{helperText}</HelperText>
    </FormControl>
  );
}
