import React from 'react';
import { Footer, ImageTopBar } from '../../common/components';
import { ScheduleList } from '../components';

export function SchedulesPage(): JSX.Element {
  return (
    <>
      <ScheduleList />
    </>
  );
}

export default SchedulesPage;
