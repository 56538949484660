import { ICreateSchedule } from "../contracts";

export function prepareAddScheduleData(data: any): ICreateSchedule {
    const formData = {
        followupSlug: 'acompanhamento_unico',
        idSchedule: data.idSchedule,
        patientDocument: data.patientDocument,
        idProduct: data.idProduct,
        idAvailability: data.idAvailability,
        idAvailabilityTime: data.idAvailabilityTime,
        observations: data.observations,
        completed: false,
    }
    
    return formData;
}