import React, { useState, useEffect } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useHistory, useParams, useLocation } from 'react-router-dom'; 
import { Typography } from '@material-ui/core';
import { noop } from 'lodash';
import { Button } from '../../common/styles';
import { AddMoreText, Box, Container } from '../../common/styles/FormStyles';
import { localStorageMethods } from '../../common/helpers';
import { IMedicineFormData } from '../contracts';
import { PatientCodeAndBirthdayInputBox, MedicineAndDoctorInputBox } from '../../common/components';
import { IPatientCrendentials, IUseFormData, LocalStorageKeysEnum } from '../../common/contracts';
import { RoutesEnum } from '../../../../constants';
import { dynamicQuestionsService, medicinesService } from '../../../../services';
import { useAlert } from '../../../../contexts/patient-portal/Alert.context';
import { medicineFormAlertMessages, prepareAddMedicineData } from '../helpers';
import envConfig from '../../../../config/environment-config';
import { useQueryClient } from 'react-query';
import { useLoader } from '../../../../contexts/patient-portal/Loader.context';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';

export function MedicineForm(): JSX.Element {
  interface RouteParams {
    id: string;
  }
  const queryClient = useQueryClient();
  const { showLoader, hideLoader } = useLoader();
  const { push } = useHistory();
  const { id } = useParams<RouteParams>();
  const location = useLocation(); 
  const [medicinsInputsList, setMedicinsInputsList] = useState([0]);
  const [isEditMode, setIsEditMode] = useState(false);
  const handleAddMedicine = () => {
    setMedicinsInputsList([...medicinsInputsList, 1]);
  };
  const { showAlert, hideAlert } = useAlert();
  const patientCredentials = localStorageMethods.getItem<IPatientCrendentials>(LocalStorageKeysEnum.PATIENT_CREDENTIALS);
  const { showDialogue } = useDialogue();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control,
    setValue,
    getValues,
    watch,
  } = useForm();

  const formData: IUseFormData = {
    register,
    handleSubmit,
    errors,
    setError,
    control,
    setValue,
    watch,
    getValues,
    unregister: noop,
  };
  const pathname = location.pathname; 
  const origin = pathname.includes('/unimed') ? '/unimed' : '';
  const { data: allMedicines } = medicinesService.findManyByProgramCode(envConfig.boehringerProgramCode, pathname);
  const {
    data: questions,
  } = dynamicQuestionsService.findManyByProgramCode(envConfig.boehringerProgramCode, pathname);

  const onSubmit = async (data: any) => {
    if (!allMedicines || !questions) {
      return showDialogue('Dados dos medicamentos', 'Ops! Tivemos um erro ao obter os dados dos medicamentos, tente novamente mais tarde!', 'error');
    }
    const { success, error } = medicineFormAlertMessages;
    const preparedData = prepareAddMedicineData(data.medication);
    showLoader();
    try {
      await medicinesService.addByPatient(preparedData, pathname);
      await queryClient.invalidateQueries([LocalStorageKeysEnum.FIND_MEDICINES_BY_PATIENT, patientCredentials?.healthPassportId]);
      showAlert(success.title, success.subTitle, success.status);
      setTimeout(() => hideAlert(), 3000);
      setTimeout(() => push(`${RoutesEnum.PATIENT_MEDICATION_ROUTE}${origin}`), 3200);
      hideLoader();
    } catch (e: any) {
      hideLoader();
      const errorMessage = e?.response?.data?.message || ''
      showAlert(error.title, errorMessage, error.status);
      setTimeout(() => hideAlert(), 3000);
    }
  };

  // Load existing data for editing
  useEffect(() => {
    if (id) {
      setIsEditMode(true);
      const existingData = localStorageMethods
        .getItem<IMedicineFormData>(LocalStorageKeysEnum.MEDICINE_FORM_DATA);
      if (existingData) {
        Object.keys(existingData).forEach((key: string) => {
          setValue(key, existingData[key as keyof IMedicineFormData]);
        });
        setMedicinsInputsList(existingData.medication.map((_: any, index: number) => index));
      }
    }
  }, [id, setValue]);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant="body2" component="span" style={{ color: '#000000', cursor: 'pointer' }} onClick={() => push(`${RoutesEnum.PATIENT_MEDICATION_ROUTE}${origin}`)}> 
          <b>Meus medicamentos</b>
        </Typography>
        <Typography variant="body2" component="span" style={{ color: '#808080', margin: '0 5px' }}>
          /
        </Typography>
        <Typography variant="body2" component="span" style={{ color: '#808080' }}>
          {isEditMode ? 'Editar medicamentos' : 'Adicionar medicamentos'}
        </Typography>

        <hr style={{ border: '1px solid lightgray', margin: '24px 0', width: '100%' }} />

        <Box>
          {
            medicinsInputsList
              .map((value, index) => (
                <MedicineAndDoctorInputBox
                  index={index}
                  key={index}
                  formData={formData}
                  medicinsList={allMedicines?.data?.medicines}
                />
              ))
          }
        </Box>

        <Box onClick={handleAddMedicine}>
          <AddMoreText>
            + Adicionar medicamento
          </AddMoreText>
        </Box>

        <Button type='default'>
          <button type="submit">{isEditMode ? 'Atualizar' : 'Adicionar'}</button>
        </Button>
      </form>
    </Container>
  );
}
