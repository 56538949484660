import { AxiosResponse } from 'axios';
import { authenticatedPostRequest } from './authenticatedRequestService';

async function verifyRecaptcha(recaptchaValue: string): Promise<AxiosResponse<{ success: boolean; challenge_ts: string; hostname: string }>> {
    const method = 'integration/VerifyRecaptcha';
    const body = { recaptchaValue };
    return await authenticatedPostRequest(method, body);
}

export const verifyRecaptchaService = {
    verifyRecaptcha,
};
