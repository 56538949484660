import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

interface IInputListProps {
    data: Array<{
        tradeName: string;
        address: string;
        phone: string;
    }>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: 500,
            maxWidth: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        listItem: {
            padding: theme.spacing(1),
            margin: theme.spacing(0),
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
        },
        primaryText: {
            fontSize: '1.2rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
            },
        },
        secondaryText: {
            fontSize: '0.8rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '0.7rem',
                wordBreak: 'break-word',
            },
        },
    })
)

function formatCEP(cep: string) {
    cep = cep.replace(/\D/g, '');

    if (cep.length === 8) {
        return `${cep.slice(0, 5)}-${cep.slice(5)}`;
    }
    return cep;
}

function renderRow(props: ListChildComponentProps & { data: IInputListProps['data'] }) {
    const { index, style, data } = props;
    const classes = useStyles();
    const item = data[index];
    const formattedZipCode = formatCEP(item.zipCode);

    return (
        <ListItem button style={style} key={index} className={classes.listItem}>
            <ListItemText
                primary={<span className={classes.primaryText}>{item.tradeName}</span>}
                secondary={
                    <span className={classes.secondaryText}>
                        {item.address}, {item.city}, {item.state} - {formattedZipCode}
                        <br />
                        {item.phone}
                    </span>
                }
            />
        </ListItem>
    );
}

export default function VirtualizedList({ data }: IInputListProps) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <FixedSizeList
                height={500}
                width="100%"
                itemSize={80}
                itemCount={data.length}
                itemData={data}
            >
                {({ index, style }) => renderRow({ index, style, data })}
            </FixedSizeList>
        </div>
    );
}  
