import React from 'react';
import { ScheduleForm } from '../components';

export function SchedulesUpsertPage(): JSX.Element {
  return (
    <>
      <ScheduleForm />
    </>
  );
}

export default SchedulesUpsertPage;
