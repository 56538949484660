import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { GenderEnum, IDefaultValueRegistrationFormData, IMedicineFormData, IRegistrationFormData, IUpdatedPatient } from '../contracts';
import { DynamicResponse, GetPatient, Medication, SignupPatientParams, UpdatePatientParams } from '../contracts/signupInterface';
import envConfig from '../../../../config/environment-config';

export function handleDefaultValueData(
  patientData?: GetPatient,
): IDefaultValueRegistrationFormData | undefined {
  if (!patientData) return undefined;

  return {
    name: patientData.name,
    cpf: patientData.cpf,
    birthDate: patientData.birthDate,
    gender: patientData.gender as GenderEnum,
    DDD: patientData.cellphone?.slice(0, 2),
    phone: patientData.cellphone?.slice(2),
    email: patientData.email,
    cep: `${patientData.cep?.slice(0, 5)}-${patientData.cep?.slice(5)}`,
    address: patientData.address,
    numberHouse: patientData.numberHouse,
    complement: patientData.neighborhood,
    city: patientData.city,
    uf: patientData.uf,
    user_dynamic_questions: patientData
      ?.user_dynamic_questions?.map((value) => {
        if (!Array.isArray(value?.CodeResponse)) {
          return {
            ...value,
            CodeResponse: [value.CodeResponse],
          };
        }

        return value;
      }),
  };
}

export function setDefaultValues(
  defaultValue: IRegistrationFormData,
  setValue: UseFormSetValue<FieldValues>,
) {
  if (defaultValue) {
    setValue('cpf', defaultValue?.cpf);
    setValue('birthDate', defaultValue?.birthDate);
    setValue('name', defaultValue?.name);
    setValue('gender', defaultValue?.gender);
    setValue('DDD', defaultValue?.DDD);
    setValue('phone', defaultValue?.phone);
    setValue('cep', defaultValue?.cep);
    setValue('numberHouse', defaultValue?.numberHouse);
    setValue('complement', defaultValue?.complement);
    setValue('city', defaultValue?.city);
    setValue('uf', defaultValue?.uf);
    setValue('address', defaultValue?.address);
    setValue('email', defaultValue?.email);
  }
}

export function prepareUpdateBodyData(
  registrationFormData: IDefaultValueRegistrationFormData,
  healthPassportId: string,
): UpdatePatientParams {
  const cellphone = registrationFormData.DDD + registrationFormData.phone;
  const userDynamicQuestions = registrationFormData.user_dynamic_questions
    ?.filter((value) => {
      const condition = value !== undefined && value.CodeResponse && value.CodeResponse !== '';
      return condition;
    });

  return {
    code: envConfig.originCode,
    type: 'type', // TODO
    programId: envConfig.boehringerProgramCode,
    healthPassportId,
    user: {
      name: registrationFormData.name,
      birthDate: registrationFormData.birthDate ?? '',
      gender: registrationFormData.gender,
      cellphone,
      email: registrationFormData.email,
      password: registrationFormData.password,
      confirmPassword: registrationFormData.confirmPassword,
      oldPassword: registrationFormData.oldPassword,
      cep: registrationFormData.cep,
      address: registrationFormData.address,
      numberHouse: registrationFormData.numberHouse,
      neighborhood: registrationFormData.complement,
      city: registrationFormData.city,
      uf: registrationFormData.uf,
      // eslint-disable-next-line
      user_dynamic_questions: userDynamicQuestions,
    },
  };
}

function handleMedicationData(medicineFormData: IMedicineFormData): Medication[] {
  return medicineFormData.medication.map((value) => {
    const medicationDynamicQuestions = value.medication_dynamic_questions.filter(
      (dynamicQuestion) => {
        if (Array.isArray(dynamicQuestion)) {
          return dynamicQuestion.every((question) => {
            const condition =
              question &&
              typeof question === "object" &&
              question.CodeQuestion &&
              question.CodeProductRule;
            return condition;
          });
        } else if (dynamicQuestion && typeof dynamicQuestion === "object") {
          const condition =
            dynamicQuestion.CodeQuestion &&
            dynamicQuestion.CodeProductRule;
          return condition;
        }
        return false;
      }
    );

    return {
      ...value,
      medication_dynamic_questions: medicationDynamicQuestions,
    };
  });
}

function handleUserDynamicQuestions(registrationFormData: IRegistrationFormData): DynamicResponse[] {
  return registrationFormData.user_dynamic_questions
    ?.filter((value) => {
      const condition = value !== undefined && value.CodeResponse && value.CodeResponse !== '';
      return condition;
    });
}

export function prepareCreateBodyData({
  medicineFormData,
  registrationFormData,
}: {
  medicineFormData: IMedicineFormData,
  registrationFormData: IRegistrationFormData,
}): SignupPatientParams {
  const acceptTermsAndContacts = registrationFormData.confirmation && registrationFormData.terms;
  const userDynamicQuestions = handleUserDynamicQuestions(registrationFormData);
  const medication = handleMedicationData(medicineFormData);

  return {
    code: envConfig.originCode,
    type: 'type',
    programId: envConfig.boehringerProgramCode,
    user: {
      cpf: medicineFormData.cpf.replace(/\D/g, ''),
      birthDate: medicineFormData.birthDate,
      name: registrationFormData.name,
      gender: registrationFormData.gender,
      email: registrationFormData.email,
      cellphone: registrationFormData.DDD + registrationFormData.phone,
      uf: registrationFormData.uf,
      city: registrationFormData.city,
      address: registrationFormData.address,
      neighborhood: registrationFormData?.complement,
      numberHouse: registrationFormData.numberHouse,
      cep: registrationFormData.cep,
      password: registrationFormData.password,
      acceptTermsAndContacts,
      acceptSMS: registrationFormData.acceptSMS,
      acceptWhatsapp: registrationFormData.acceptWhatsapp,
      acceptEmail: registrationFormData.acceptEmail,
      user_dynamic_questions: userDynamicQuestions,
    },
    medication,
  };
}
