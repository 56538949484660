import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  medicineName: {
    color: 'rgb(34, 34, 34)',
    fontSize: 'large',
    fontWeight: 'bolder',
    marginBottom: '10px',
  },
  medicineQuantity: {
    color: 'rgb(34, 34, 34)',
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px',
  },
  medicineDiscount: {
    color: 'rgb(199, 43, 4)',
    fontSize: 'large',
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '10px',
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  storeButton: {
    alignItems: 'center',
    alignContent: 'center',
    color: '#08312A',
    backgroundColor: '#00E47C',
    border: '1px #00E47C',
    borderRadius: '20px',
    fontWeight: 'bold',
    height: '45px',
    width: '90%',
  },
  storeText: {
    color: 'rgb(194, 96, 4)',
    fontWeight: 'bold',
    marginBottom: '10px',
    marginTop: '15px',
    cursor: 'pointer',
  },
});

export default useStyles;
