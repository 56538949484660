import React from 'react';
import Navbar from '../../components/Navbar';

type NavbarComposerProps = {
  isDrawerOpen?: boolean;
  onDrawerOpen?: () => void;
  onDrawerClose?: () => void;
};

export default function NavbarComposer(props: NavbarComposerProps): JSX.Element {
  return <Navbar {...props} />;
}
