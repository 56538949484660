import React, { useState } from 'react';
import styled from 'styled-components';
import { Card, Filter, GroupDivider } from '../components';
import { Container } from '@material-ui/core';

const medicines = [
  { product: 'Giotrif', name: 'Giotrif®', activeIngredient: 'Afatinib' },
  { product: 'Glyxambi', name: 'Glyxambi® 10/5mg', activeIngredient: 'Linagliptina e Empagliflozina' },
  { product: 'Glyxambi', name: 'Glyxambi® 25/5mg', activeIngredient: 'Linagliptina e Empagliflozina' },
  { product: 'Jardiance', name: 'Jardiance® 25mg', activeIngredient: 'Empagliflozina' },
  { product: 'Jardiance', name: 'Jardiance® 10mg', activeIngredient: 'Empagliflozina' },
  { product: 'Jardiance DUO', name: 'Jardiance DUO® 12,5mg/850mg', activeIngredient: 'Empagliflozina e Cloridrato de Metformina' },
  { product: 'Jardiance DUO', name: 'Jardiance DUO® 12,5mg/1000mg', activeIngredient: 'Empagliflozina e Cloridrato de Metformina' },
  { product: 'Micardis', name: 'Micardis® 40mg', activeIngredient: 'Telmisartana' },
  { product: 'Micardis', name: 'Micardis® 80mg', activeIngredient: 'Telmisartana' },
  { product: 'Micardis HCT', name: 'Micardis® HCT 40/12,5mg', activeIngredient: 'Telmisartana e Hidroclorotiazida' },
  { product: 'Micardis HCT', name: 'Micardis® HCT 80/12,5mg', activeIngredient: 'Telmisartana e Hidroclorotiazida' },
  { product: 'Micardis HCT', name: 'Micardis® HCT 80/25mg', activeIngredient: 'Telmisartana e Hidroclorotiazida' },
  { product: 'Ofev', name: 'Ofev®', activeIngredient: 'Nintedanib' },
  { product: 'Spiolto', name: 'Spiolto®', activeIngredient: 'Brometo de Tiotrópio Monoidratado e Cloridrato de Olodaterol' },
  { product: 'Spiriva', name: 'Spiriva®', activeIngredient: 'Brometo de Tiotrópio e Brometo de Tiotrópio Monoidratado' },
  { product: 'Trayenta', name: 'Trayenta®', activeIngredient: 'Linagliptina' },
];

const AppContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    width: 95%;
    padding: 10px;
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const Title = styled.h2`
  font-family: Roboto;
  font-weight: bold;
  line-height: 32.17px;
  text-align: left;
  margin-bottom: 20px;
  gap: 24px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 25px;
  }
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 1px;
  background-color: #E7E8E8;
  margin-bottom: 20px;
`;

interface Medicine {
  product: string;
  name: string;
  activeIngredient: string;
}

interface Groups {
  [letter: string]: Medicine[];
}

export default function MedicineLeafletsPage(): JSX.Element {
  const [filter, setFilter] = useState('');

  const filteredMedicines = medicines.filter(
    (medicine) => medicine.name.toLowerCase().includes(filter.toLowerCase()),
  );

  const groupedMedicines = filteredMedicines.reduce<Groups>((acc, medicine) => {
    const letter = medicine.name[0].toUpperCase();
    if (!acc[letter]) acc[letter] = [];
    acc[letter].push(medicine);
    return acc;
  }, {});

  return (
    <>
      <Container>
        <Title>
          Bula de medicamentos
        </Title>
        <HorizontalLine />
        <Filter filter={filter} setFilter={setFilter} />
        {Object.keys(groupedMedicines).sort().map((letter) => (
          <div key={letter}>
            <GroupDivider letter={letter} />
            <CardsContainer>
              {groupedMedicines[letter].map((medicine) => (
                <Card key={`${medicine.product}-${medicine.name}-${medicine.activeIngredient}`} {...medicine} />
              ))}
            </CardsContainer>
          </div>
        ))}
      </Container>
    </>
  );
}
