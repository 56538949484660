import React from 'react';
import { MedicineForm, SignupPhases, SignupWelcome } from '../components';
import { Footer, ImageTopBar } from '../../common/components';

export default function MedicineSignupPage(): JSX.Element {
  return (
    <>
      <ImageTopBar />
      <SignupPhases activeStep={0} />
      <SignupWelcome
        title="Que bom ter você aqui no Abraçar a Vida!"
        subtitle="Caso você utilize mais de um medicamento da Boehringer, você poderá adicioná-lo abaixo. Caso queira, clique em "
        strongMessage="+ Adicionar medicamento."
        description="Para continuar, por favor, preencha as informações abaixo:"
      />
      <MedicineForm />
      <Footer />
    </>
  );
}
