/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Typography, Container, Box, Grid, Button, TextField, Chip, Select, MenuItem, useMediaQuery, CircularProgress } from '@material-ui/core';
import { InsertDriveFileOutlined, DateRangeOutlined, ConfirmationNumberOutlined, SearchOutlined, SentimentDissatisfied } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';

const categoryStyles: Record<string, string> = {
  "Saúde": "#E0F7FA",
  "Atividade Física": "#FFECB3",
  "Autocuidado": "#FFCDD2",
  "Hipertensão Arterial": "#C8E6C9",
  "Especialidades": "#D1C4E9",
};

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    border: '1px solid #eee',
    borderRadius: '8px',
    backgroundColor: '#fff',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.03)',
      '& img': {
        transform: 'scale(1.1)',
      }
    }
  },
  imageBox: {
    width: '100%',
    height: 'auto',
    maxHeight: '300px',
    overflow: 'hidden',
    position: 'relative',
  },
  image: {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    maxHeight: '100%',
  },
  title: {
    fontWeight: 'bold',
    color: 'var(--primary-main)',
    marginTop: '8px',
    height: '45px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  content: {
    color: '#556275',
    marginTop: '8px',
    height: '60px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }
});

type NewsItem = {
  title: string;
  content: string;
  categories: string[];
  fileName: string;
  image: string;
};

const noticias: NewsItem[] = [
  {
    title: "O que é diabetes?",
    content: "Quantas vezes você já ouviu falar que o açúcar em excesso faz mal para a saúde? Apesar de essa ser uma informação já conhecida por muitas pessoas, o conhecimento sobre o que é o diabetes de fato ainda é escasso.",
    categories: ["Saúde"],
    fileName: 'contents/o-que-e-diabetes',
    image: 'o-que-e-diabetes.webp'
  },
  {
    title: "Mitos e verdades sobre alimentação e diabetes",
    content: "Quem convive com o diabetes sabe que não faltam dicas e receitas milagrosas que prometem controlar a glicemia. Mas afinal, o que realmente é verdade e o que é mentira?",
    categories: ["Saúde"],
    fileName: 'contents/mitos-e-verdades-sobre-alimentacao-e-diabetes',
    image: 'mitos-e-verdades-sobre-alimentacao-e-diabetes.webp'
  },
  {
    title: "Todo movimento conta",
    content: "Você quer incorporar a atividade física na sua rotina, mas não sabe o que fazer nem por onde começar?",
    categories: ["Atividade Física"],
    fileName: 'contents/todo-movimento-conta',
    image: 'todo-movimento-conta.webp'
  },
  {
    title: "Como tratar a hipoglicemia",
    content: "A hipoglicemia é uma complicação aguda que se caracteriza por níveis baixos de glicose no sangue.",
    categories: ["Saúde", "Hipertensão Arterial"],
    fileName: 'contents/como-tratar-a-hipoglicemia',
    image: 'como-tratar-a-hipoglicemia.webp'
  },
  {
    title: "Os sete comportamentos do autocuidado",
    content: "O autocuidado pode ser definido através de atividades que o indivíduo realiza em benefício de si próprio.",
    categories: ["Autocuidado"],
    fileName: 'contents/os-sete-comportamentos-do-autocuidado',
    image: 'os-sete-comportamentos-do-autocuidado.webp'
  },
  {
    title: "Dieta DASH: alimentação saudável para hipertensos",
    content: "Que a alimentação faz toda diferença na sua saúde você já deve saber, certo?",
    categories: ["Saúde"],
    fileName: 'contents/dieta-dash-alimentacao-saudavel-para-hipertensos-1-3',
    image: 'dieta-dash-alimentacao-saudavel-para-hipertensos-1-3.webp'
  },
  {
    title: "O que é hipertensão arterial?",
    content: "A pressão arterial é um daqueles assuntos de saúde que todo mundo acha que entende um pouco.",
    categories: ["Saúde", "Hipertensão Arterial"],
    fileName: 'contents/o-que-e-hipertensao-arterial',
    image: 'o-que-e-hipertensao-arterial.webp'
  },
  {
    title: "Tratamento para hipertensão",
    content: "Ser hipertenso significa que o coração tem que trabalhar mais para vencer os altos valores de pressão.",
    categories: ["Saúde", "Especialidades"],
    fileName: 'contents/tratamento-para-hipertensao',
    image: 'tratamento-para-hipertensao.webp'
  }
];

const categories = ["Todas as categorias", "Saúde", "Atividade Física", "Autocuidado", "Hipertensão Arterial", "Especialidades"];

const DashboardPage: React.FC<any> = (): JSX.Element => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const origin = window.location.pathname.includes('/unimed') ? '/unimed' : '';
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Todas as categorias");
  const [filteredNews, setFilteredNews] = useState<NewsItem[]>(noticias);
  const [showSurveyPopup, setShowSurveyPopup] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    
    setSearchTerm(term);
    filterNews(term, selectedCategory);
  };

  const handleCategoryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const category = event.target.value as string;
    
    setSelectedCategory(category);
    filterNews(searchTerm, category);
  };

  const filterNews = (term: string, category: string) => {
    const filtered = noticias.filter(news => {
      const matchesSearch = news.title.toLowerCase().includes(term) || news.content.toLowerCase().includes(term);
      const matchesCategory = category === "Todas as categorias" || news.categories.includes(category);
      
      return matchesSearch && matchesCategory;
    });

    setFilteredNews(filtered);
  };

  useEffect(() => {
    const timer = setTimeout(() => setShowSurveyPopup(true), 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleClosePopup = () => {
    setShowSurveyPopup(false);
  };

  return (
    <>
      <Container>
        <Box my={2}>
          <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
            Acesso rápido
          </Typography>
          <hr style={{ border: '1px solid lightgray', margin: '24px 0 36px 0' }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Link to={`/panel/my-medicines${origin}`} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  startIcon={<InsertDriveFileOutlined />}
                  fullWidth
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    justifyContent: 'flex-start',
                    padding: '5px 25px'
                  }}
                >
                  <div style={{ textAlign: 'left', padding: '5px 12px' }}>
                    Meus<br />medicamentos
                  </div>
                </Button>
              </Link>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Link to={`/panel/schedules${origin}`} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  startIcon={<DateRangeOutlined />}
                  fullWidth
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    justifyContent: 'flex-start',
                    padding: '5px 25px'
                  }}
                >
                  <div style={{ textAlign: 'left', padding: '5px 12px' }}>
                    Meus<br />agendamentos
                  </div>
                </Button>
              </Link>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Link to={`/panel/discounts${origin}`} style={{ textDecoration: 'none' }}>
                <Button
                  variant="contained"
                  startIcon={<ConfirmationNumberOutlined />}
                  fullWidth
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
                    justifyContent: 'flex-start',
                    padding: '5px 25px'
                  }}
                >
                  <div style={{ textAlign: 'left', padding: '5px 12px' }}>
                    Meus<br /> descontos
                  </div>
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>

        {/* <Box my={4}> Código comentado, pois esse componente não foi concluido a tempo para o GO-LIVE BOEHRINGER PSP
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h1" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left', marginBottom: '12px' }}>
                Meus agendamentos
              </Typography>
            </Grid>
            <Grid item>
              <Link to={`/panel/schedules${origin}`} style={{ textDecoration: 'none' }}>
                <Typography variant="body2" style={{ color: 'var(--primary-main)', cursor: 'pointer' }}>
                  Exibir mais
                </Typography>
              </Link>
            </Grid>
          </Grid>
          <Paper variant="outlined" style={{ padding: '16px', marginTop: '16px', borderRadius: '8px' }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Consultas</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Nome do especialista</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Data</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Horário</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={5} align="center" style={{ padding: '32px 0px' }}>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        Em breve as informações de agendamento estarão disponíveis.
                      </Typography>
                      <Typography variant="body2">
                        Por enquanto, para falar sobre agendamento, entre em contato com 0800 701 6633
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box> */}

        <Box my={4} py={3}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h1" style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'left' }}>
                Conteúdos
              </Typography>
            </Grid>
            <Grid item>
              <Link to={`/panel/contents${origin}`} style={{ textDecoration: 'none' }}>
                <Typography variant="body2" style={{ color: 'var(--primary-main)', cursor: 'pointer' }}>
                  Exibir mais
                </Typography>
              </Link>
            </Grid>
          </Grid>

          {/* Campo de busca e filtro de categorias */}
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems="center"
            my={2}
          >
            <TextField
              placeholder="Busque e explore nosso banco de conteúdos"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              style={{
                backgroundColor: '#fff',
                width: isSmallScreen ? '100%' : '70%',
                marginBottom: isSmallScreen ? theme.spacing(2) : 0
              }}
              InputProps={{
                startAdornment: (
                  <Box>
                    <SearchOutlined />
                  </Box>
                ),
                style: { fontSize: isSmallScreen ? '0.9rem' : '1rem' },
              }}
            />
            <Select
              value={selectedCategory}
              onChange={handleCategoryChange}
              variant="outlined"
              style={{
                width: isSmallScreen ? '100%' : '28%',
                fontSize: isSmallScreen ? '0.9rem' : '1rem'
              }}
            >
              {categories.map((category, index) => (
                <MenuItem key={index} value={category}>
                  <Typography variant="body2">{category}</Typography>
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* Verificação de resultados */}
          {filteredNews.length === 0 ? (
            <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
              <SentimentDissatisfied style={{ fontSize: '3rem', color: 'gray' }} />
              <Typography variant="h6" style={{ textAlign: 'center', color: 'gray', marginTop: '10px' }}>
                Nenhum conteúdo encontrado para os filtros selecionados.
              </Typography>
              <Typography variant="body2" style={{ textAlign: 'center', color: 'gray', marginTop: '5px' }}>
                Tente ajustar sua pesquisa ou explorar outras categorias.
              </Typography>
            </Box>
          ) : (
            <Grid container spacing={4}>
              {filteredNews.map((news, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Box className={classes.cardContainer}>
                    {/* Imagem do Card com efeito de zoom */}
                    <Box className={classes.imageBox}>
                      <img
                        src={`https://cdn-assets.fidelize.com.br/BOEHRINGER/${news.image}`}
                        alt={news.title}
                        className={classes.image}
                        loading='lazy'
                      />
                    </Box>
                    {/* Conteúdo do Card */}
                    <Box style={{ padding: '16px', flex: 1, display: 'flex', flexDirection: 'column' }}>
                      {/* Exibição das Categorias */}
                      <Box mb={1} display="flex" flexWrap="wrap">
                        {news.categories.map((category, idx) => (
                          <Chip
                            key={idx}
                            label={category}
                            style={{
                              backgroundColor: categoryStyles[category] || '#ddd',
                              color: '#333',
                              fontWeight: 'bold',
                              fontSize: '0.65rem',
                              textTransform: 'uppercase',
                              marginRight: '4px',
                              marginBottom: '4px'
                            }}
                            size="small"
                          />
                        ))}
                      </Box>
                      <Link to={`/panel/${news.fileName}`} style={{ textDecoration: 'none', color: 'inherit', flex: 1 }}>
                        <Typography variant="body1" className={classes.title}>
                          {news.title}
                        </Typography>
                        <Typography variant="body2" className={classes.content}>
                          {news.content}
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      </Container>
    </>
  );
};

export default DashboardPage;
