import { AxiosResponse } from 'axios';
import { useQuery, UseQueryResult } from 'react-query';
import { LocalStorageKeysEnum } from '../pages/portal-patient/common/contracts';
import { authenticatedGetRequest, authenticatedPostRequest } from './authenticatedRequestService';
import { ICreateSchedule, IGetScheduleById, IListAvailabilities, IListProducts, IListProfessionals, IListProfessionalType, IListSchedules, ISchedule, IUpdateSchedule } from '../pages/portal-patient/schedules/contracts';

function listProfessionalTypes(): UseQueryResult<AxiosResponse<IListProfessionalType, any>, unknown> {
  const method = 'schedule/professional-types';
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.FIND_PROFESSIONAL_TYPES, 'professional-types'],
    () => authenticatedGetRequest(
      method
    ),
    {
      staleTime,
      cacheTime,
    }
  );
}

function listCrmProducts(idIndustry: string): UseQueryResult<AxiosResponse<IListProducts, any>, unknown> {
  const method = 'schedule/crm-products';
  const params = `/${idIndustry}`;
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.FIND_CRM_PRODUCTS, 'crm-products'],
    () => authenticatedGetRequest(
      method,
      params,
    ),
    {
      staleTime,
      cacheTime,
    }
  );
}

function listProfessional(
  followupSlug: string,
  professionalType: string,
): UseQueryResult<AxiosResponse<IListProfessionals, any>, unknown> {
  const method = 'schedule/professionals';
  const params = `/${followupSlug}/${professionalType}`;
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.FIND_PROFESSIONALS, 'professionals'],
    () => authenticatedGetRequest(
      method,
      params,
    ),
    {
      staleTime,
      cacheTime,
    }
  );
}

function listAvailabilitiesByFollowupAndProfessional(
  followupSlug: string,
  professionalType: string,
): UseQueryResult<AxiosResponse<IListAvailabilities, any>, unknown> {
  const method = 'schedule/availabilities';
  const params = `/${followupSlug}/${professionalType}`;
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.FIND_PROFESSIONALS, 'professionals'],
    () => authenticatedGetRequest(
      method,
      params,
    ),
    {
      staleTime,
      cacheTime,
    }
  );
}

function listSchedules(
  followupSlug: string,
  patientDocument: string
): UseQueryResult<AxiosResponse<IListSchedules, any>, unknown> {
  const method = 'schedule/list';
  const params = `/${followupSlug}/${patientDocument}`;
  const staleTime = 6000;
  const cacheTime = 6000;

  return useQuery(
    [LocalStorageKeysEnum.FIND_SCHEDULES_BY_PATIENT_FOLLOWUP, params],
    () => authenticatedGetRequest(
      method,
      params,
    ),
    {
      staleTime,
      cacheTime,
    },
  );
}

function getSchedule(isSchedule: string): UseQueryResult<AxiosResponse<IGetScheduleById, any>, unknown> {
  const method = 'schedule/get-by-id';
  const params = `/${isSchedule}`;
  const staleTime = 60 * 10000; //ms
  const cacheTime = 60 * 30000; //ms

  return useQuery(
    [LocalStorageKeysEnum.GET_SCHEDULE, params],
    () => authenticatedGetRequest(
      method,
      params,
    ),
    {
      staleTime,
      cacheTime,
    },
  );
}

async function create(body: ICreateSchedule): Promise<AxiosResponse<ISchedule, any>> {
  const method = 'schedule/create';

  return await authenticatedPostRequest(
    method,
    { ...body },
  );
}

async function update(body: IUpdateSchedule): Promise<AxiosResponse<ISchedule, any>> {
  const method = 'schedule/update';
  const requestBody = {
    ...body
  };

  return await authenticatedPostRequest(
    method,
    requestBody,
  );
}

export const schedulesService = {
  listProfessionalTypes,
  listCrmProducts,
  listProfessional,
  listAvailabilitiesByFollowupAndProfessional,
  listSchedules,
  create,
  update,
  getSchedule,
};