import React from 'react';
import Sidebar from '../../components/Sidebar';

type SidebarComposerProps = {
  isDrawerOpen?: boolean;
  onDrawerClose?: () => void;
};

export default function SidebarComposer(props: SidebarComposerProps): JSX.Element {
  return <Sidebar {...props} />;
}
