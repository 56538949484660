import styled from 'styled-components';
import React from 'react';
import { Typography } from '@material-ui/core';

const Title = styled.h2`
    font-family: Roboto;
  font-weight: bold;
  line-height: 32.17px;
  letter-spacing: 0.36399999260902405px;
  text-align: left;
  margin-bottom: 20px;
  color: #282929;
  gap: 24px;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 25px;
  }
`;

const SubTitle = styled.h4`
  font-family: Roboto;
  font-weight: 400;
  line-height: 21.09px;
  letter-spacing: 0.3799999952316284px;
  text-align: left;
  margin-bottom: 20px;
  color: #282929;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    font-family: Roboto;
    font-weight: 400;
    line-height: 21.09px;
    letter-spacing: 0.3799999952316284px;
    text-align: left;
    font-size: 18px;
    color: #282929;
  }
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 2px;
  background-color: #E7E8E8;
  margin-bottom: 20px;
`;

export function AccreditedWelcome({
  title,
  subtitle,
}: {
  title: string,
  subtitle: string,
}): JSX.Element {
  return (
    <>
      <Typography variant="h5" style={{ fontWeight: 'bold', fontSize: '1.8rem', textAlign: 'left' }}>
        {title}
      </Typography>
      <Typography variant="h5" style={{ fontSize: '1.2rem', textAlign: 'left', marginTop: '0.5rem' }}>
        {subtitle}
      </Typography>
      <hr style={{ border: '1px solid lightgray', margin: '24px 0' }} />
    </>
  );
}
