import { IAlertMessage } from '../../common/contracts';

export const medicineFormAlertMessages: IAlertMessage = {
  success: {
    title: 'Paciente Elegível',
    subTitle: 'O paciente atende aos critérios de elegibilidade. Você será redirecionado para a próxima etapa do processo.',
    status: 'success'
  },
  error: {
    title: 'ReCAPTCHA expirado ou inválido!',
    subTitle: 'Por favor, valide o reCAPTCHA antes de continuar.',
    status: 'error'
  },
};
