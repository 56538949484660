import React, { useEffect, useState } from 'react';
import { Grow, Slider, TextField, Typography } from '@material-ui/core';
import { Box, InputsBox, inputLabelProps, inputMUIDefaultStyles } from '../styles';
import { IUseFormData } from '../../common/contracts';
import { statesList } from '../../common/helpers/statesListMock';
import { IMaskInputCEP } from '../../common/components/IMaskInput';
import { addressAndAccreditedService } from '../../../../services/addressAndAccreditedService';
import { SelectInput } from '../../common/components';
import { useDialogue } from '../../../../contexts/patient-portal/Dialogue.context';
import { stringsMatcher } from '../../common/helpers';

export function AddressBoxInputs({ formData }: { formData: IUseFormData }) {
  const { showDialogue } = useDialogue();
  const { register, errors, watch, setValue, getValues } = formData;
  const [cepIntegrationError, setCepIntegrationError] = useState<boolean>(false);
  const [listCities, setListCities] = useState<{ value: string; label: string }[]>([]);
  const [previousCep, setPreviousCep] = useState<string | undefined>(undefined);
  const [previousState, setPreviousState] = useState<string | undefined>(undefined);
  const [cepCity, setCepCity] = useState<string | undefined>(undefined);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const [listNeighborhoods, setListNeighborhoods] = useState<{ value: string; label: string }[]>([]);
  const [neighborhoodsLoading, setNeighborhoodsLoading] = useState<boolean>(false);
  const [previousCity, setPreviousCity] = useState<number | undefined>(undefined);
  const [fetchError, setFetchError] = useState<{ [key: string]: boolean }>({});  // State to manage errors
  const [cityName, setCityName] = useState<string | undefined>(undefined);
  const [neighborhoodName, setNeighborhoodName] = useState<string | undefined>(undefined);

  const [distance, setValueDistance] = React.useState<number>(5);

  const handleChange = (event: Event, distance: number | number[]) => {
    setValue('distance', distance);
    setValueDistance(distance as number);
  };

  const watchValue = watch();
  const cepField: string = watchValue?.cep;
  const stateField: string = watchValue?.uf;
  const cityField = Number(watchValue?.city);

  useEffect(() => {
    const cityTemp = listCities?.find((city) => stringsMatcher(city.label, cepCity ?? ''));
    
    if (cityTemp && cepField?.length === 9) {
      setValue('city', cityTemp.value);
      setCityName(cityTemp.label);
      setValue('cityName', cityTemp.label);
    }
  }, [cepField, listCities]);

  useEffect(() => {
    console.log('chama')
    const fetchCities = async (state: string) => {
      setCitiesLoading(true);
      setListCities([]);
      try {
        const data = await addressAndAccreditedService.getListCities(1, state);
        const dataIsInvalid = !data || !Array.isArray(data?.listCities)
        
        if (dataIsInvalid) return showDialogue('Lista de Cidades', 'Não foi possível obter a lista de cidades, tente novamente mais tarde!', 'error');
        
        const cities = data.listCities.map((city) => ({
          value: String(city.code),
          label: city.name.charAt(0).toUpperCase() + city.name.slice(1).toLowerCase(),
        }));
            
        setListCities(cities);
        setPreviousState(state);

        if (data?.listCities?.length === 0) showDialogue('Lista de Cidades', 'Não foram encontradas cidades cadastradas para este estado', 'info');
      } catch (error) {
        showDialogue('Lista de Cidades', 'Não foi possível obter a lista de cidades, tente novamente mais tarde!', 'error');
        setFetchError((prev) => ({ ...prev, cities: true }));
      } finally {
        setCitiesLoading(false);
      }
    }; 

    if (stateField && stateField !== previousState && !citiesLoading) {
      fetchCities(stateField); 
    }
  }, [stateField, previousState, cepCity, citiesLoading]);

  useEffect(() => {
    setPreviousState(stateField);
  }, [stateField]);

  useEffect(() => {
    const fetchNeighborhoods = async (city: number, state: string) => {
      setNeighborhoodsLoading(true);
      try {
        const data = await addressAndAccreditedService.listNeighborhoods(1, city, state);
        if (data && Array.isArray(data.listNeighborhoods)) {
          const neighborhoods = data.listNeighborhoods.map((neighborhood) => ({
            value: String(neighborhood.code),
            label: neighborhood.description.charAt(0).toUpperCase() + neighborhood.description.slice(1).toLowerCase(),
          }));
          setListNeighborhoods(neighborhoods);
        } else {
          showDialogue('Lista de Bairros', 'Não foi possível obter a lista de bairros, tente novamente mais tarde!', 'info');
        }
      } catch (error) {
        showDialogue('Lista de Bairros', 'Não foi possível obter a lista de bairros, tente novamente mais tarde!', 'info');
        setFetchError((prev) => ({ ...prev, neighborhoods: true }));
      } finally {
        setNeighborhoodsLoading(false);
      }
    };

    if (cityField && stateField && cityField !== previousCity && !fetchError.neighborhoods) {
      fetchNeighborhoods(cityField, stateField);
      setPreviousCity(cityField);
    }
  }, [cityField, stateField, previousCity, fetchError.neighborhoods]);

  useEffect(() => {
    setValue('neighborhood', undefined);
    const fetchAddress = async () => {
      try {
        const addressData = await addressAndAccreditedService.getAddressByCEP(cepField);
        if (addressData.erro) {
          setCepIntegrationError(true);
        } else {
          setCepIntegrationError(false);
          setValue('address', addressData.logradouro || '');

          const formattedCity = addressData.localidade
            .charAt(0)
            .toUpperCase() + addressData.localidade.slice(1).toLowerCase();
          setCepCity(formattedCity);
          setValue('uf', addressData.uf || '');

          setPreviousState(undefined);
          setPreviousCity(undefined);
          setValue('city', undefined);
          setListCities([]);
          setListNeighborhoods([]);
          setValue('neighborhood', undefined);
        }
      } catch (error) {
        showDialogue('Erro ao buscar endereço:', `${error}`, 'error');
        setCepIntegrationError(true);
        setFetchError((prev) => ({ ...prev, address: true }));
      }
    };

    if (cepField?.length === 9 && cepField !== previousCep && !fetchError.address) {
      setPreviousCep(cepField);
      fetchAddress();
    }
  }, [cepField, previousCep, setValue, fetchError.address]);

  const handleFieldChange = (field: string) => {
    setFetchError((prev) => ({ ...prev, [field]: false }));
    if (field === 'cep') {
      setPreviousCep(undefined);
      setValue('cep', '');
      setPreviousCity(undefined);
      setValue('city', undefined);
      setListCities([]);
      setListNeighborhoods([]);
      setValue('neighborhood', undefined);
    } else if (field === 'uf') {
      setPreviousCep(undefined);
      setValue('cep', '');
      setPreviousCity(undefined);
      setValue('city', undefined);
      setListCities([]);
      setListNeighborhoods([]);
      setValue('neighborhood', undefined);
    } else if (field === 'city') {
      setPreviousCity(undefined);
      setListNeighborhoods([]);
      setValue('neighborhood', undefined);
      setValue('cep', '');
    }
  };

  return (
    <>
      <Box>
        <InputsBox>
          <TextField
            variant="outlined"
            id="cep"
            label="CEP"
            autoComplete="cep"
            style={inputMUIDefaultStyles.large}
            error={!!errors.cep || cepIntegrationError} 
            helperText={errors.cep ? errors.cep.message : (cepIntegrationError ? 'Cep inválido' : 'Digite o CEP para melhorar a sua busca')}
            {...register('cep', {
              validate: {
                checkError: () => !cepIntegrationError || 'Cep inválido',
              },
            })}
            InputLabelProps={{
              ...inputLabelProps,
              shrink: !!getValues('cep'),
            }}
            InputProps={{
              inputComponent: IMaskInputCEP as any,
            }}
            onChange={(event) => {
              const value = event.target.value;
              setValue('cep', value);
              setCepIntegrationError(false);
              setFetchError((prev) => ({ ...prev, address: false }));
            }}
          />
          <SelectInput
            title="Estado *"
            list={statesList}
            style={inputMUIDefaultStyles.large}
            formData={formData}
            propertieFormName="uf"
            onChange={(event: { target: { value: any } }) => {
              const newValue = event.target.value;
              setValue('uf', newValue);
              handleFieldChange('uf');
            }}
          />
        </InputsBox>
      </Box>

      <Box>
        <InputsBox>
          <SelectInput
            title="Cidade *"
            list={listCities}
            style={inputMUIDefaultStyles.large}
            formData={formData}
            propertieFormName="city"
            onChange={(event: { target: { value: any } }) => {
              const newValue = Number(event.target.value);
              const selectedCity = listCities.find(city => city.value === String(newValue));
              setValue('city', newValue);
              handleFieldChange('city');

              if (selectedCity) {
                setCityName(selectedCity.label);
                setValue('cityName', selectedCity.label);
              }
            }}
            disabled={!stateField || listCities.length === 0}
          />
          <SelectInput
            title="Bairro"
            list={listNeighborhoods}
            style={inputMUIDefaultStyles.large}
            formData={formData}
            propertieFormName="neighborhood"
            isRequired={cepField?.length !== 9}
            onChange={(event: { target: { value: any } }) => {
              const newValue = Number(event.target.value);
              const selectedNeighborhood = listNeighborhoods.find(neighborhood => neighborhood.value === String(newValue));
              setValue('neighborhood', newValue);

              if (selectedNeighborhood) {
                setNeighborhoodName(selectedNeighborhood.label);
                setValue('neighborhoodName', selectedNeighborhood.label);
              }
            }}
            disabled={!stateField || listNeighborhoods.length === 0 || cepField?.length === 9}
            helperText={cepField?.length === 9 ? 'A busca por CEP dispensa a necessidade de informar o bairro' : undefined}
          />
        </InputsBox>
      </Box>
      {
        (cepField?.length === 9 && !cepIntegrationError) && (
          <Grow in={cepField?.length === 9 && !cepIntegrationError} timeout={800}>
            <Box style={{width: '300px'}} >
              <Typography variant="h6">Raio de distância: {distance} km</Typography>
              <Slider
                value={distance}
                onChange={handleChange as any}
                min={1}
                max={20}
                step={1}
                valueLabelDisplay="auto"
              />
            </Box>
          </Grow>
        )
      }
    </>
  );
}
