import EnvConfig from "../types/config"

const envConfig: EnvConfig = {
  boehringerProgramCode: process.env['REACT_APP_BOEHRINGER_PROGRAM_CODE'] ? parseInt(process.env['REACT_APP_BOEHRINGER_PROGRAM_CODE']) : -1,
  boehringerProgramFollowup: process.env['REACT_APP_BOEHRINGER_PROGRAM_FOLLOWUP'] || '',
  boehringerCrmIdIndustry: process.env['REACT_APP_BOEHRINGER_IDINDUSTRY'] || '',
  patientPortalApiUrl: process.env['REACT_APP_PORTAL_PATIENT_API_URL'] || '',
  originCode: process.env['REACT_APP_ORIGIN_CODE'] ? parseInt(process.env['REACT_APP_ORIGIN_CODE']) : -1,
  jwtToken: process.env['REACT_APP_JWT_TOKEN_MASTER'] || '',
  doctorPortal: process.env['REACT_APP_DOCTOR_PORTAL_URL'] || '',
  originCodeUnimed: process.env['REACT_APP_ORIGIN_CODE_UNIMED'] ? parseInt(process.env['REACT_APP_ORIGIN_CODE_UNIMED']) : -1,
  healthPassportPasswordResetUrl: process.env['REACT_APP_HEALTH_PASSPORT_PASSWORD_RESET_URL'] || '',
  googleApiKey: process.env['REACT_APP_GOOGLE_API_KEY'] || '',
  googleSiteKey: process.env['REACT_APP_GOOGLE_SITE_KEY'] || '', 
}
  
export default envConfig
