import React from 'react';

export function ImageTopBar(): JSX.Element {
  return (
    <div style={{ display: 'flex', border: '1px solid #ccc', boxShadow: '0px 4px 4px 0px #00000040', alignItems: 'center', justifyContent: 'center', height: '100px' }}>
      <div>
        <img src="/assets/img/logo/logo-abracar-a-vida.png" alt="Programa Abraçar a Vida" style={{ width: '200px' }} />
      </div>
    </div>
  );
}
